@import "variables";

.contact {
    margin-top: 120px;
}

.contact__body {
    display: flex;
    justify-content: space-between;
}

.contact__message {
    display: flex;
    flex-direction: column;

    width: 422px;
    max-width: 100%;

    .title {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;

        color: #0f001a;

        margin-top: 35px;
        margin-bottom: 40px;
    }

    form {
        display: flex;
        flex-direction: column;

        .text-on-input {
            position: relative;
            width: 100%;

            box-sizing: border-box;
            border-radius: 4px;

            margin-bottom: 24px;
        }

        .text-on-input input,
        textarea {
            width: 100%;

            padding: 14px;

            border: 1.2px solid #b4bec8;
            box-sizing: border-box;
            border-radius: 4px;

            font-family: "abeezee";
            color: #0f001a;
        }

        .text-on-input input:focus,
        textarea:focus {
            outline: none !important;
            border: 2px solid $primary;
            box-shadow: 0 0 10px #719ece;
        }

        .text-on-input input.error,
        textarea.error {
            outline: none !important;
            border: 2px solid $error;
        }

        .text-on-input input::placeholder,
        textarea::placeholder {
            color: #878787;
        }

        .text-on-input textarea {
            width: inherit;
            resize: none;
        }

        .text-on-input label {
            position: absolute;
            top: -15px;
            left: 23px;
            padding: 2px 5px;
            z-index: 1;

            font-size: 16px;
            line-height: 19px;

            color: #0f001a;
            opacity: 1;
        }

        .text-on-input label:after {
            content: " ";
            background-color: #fff;
            width: 100%;
            height: 13px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
        }

        label {
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 0.5rem;
        }

        .form-control {
            box-shadow: none !important;
        }

        button {
            height: 50px;

            margin-top: 15px;

            background-color: $primary;

            border: unset;
            border-radius: 4px;

            color: white;
            font-weight: bold;

            cursor: pointer;
            transition: border 0.25s ease-in-out;
        }

        button:hover {
            border: 2px solid $accent;
        }
    }
}

.contact__location {
    width: 348px;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact__location_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 30px;
}

.contact__location_row a {
    text-decoration: none;
    align-items: center;
    display: flex;
}

.contact__location_row_icon {
    height: 20px;
    width: 20px;

    color: #0f001a;

    margin-right: 14px;
}

.contact__location_row_text {
    flex-grow: 1;

    font-size: 16px;
    line-height: 22px;
    color: #0f001a;
}

@media screen and (max-width: 900px) {
    .contact__body {
        flex-direction: column;
        align-items: center;
    }

    .contact__message {
        margin-bottom: 50px;
    }
}
